import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from './CompanyLogin.module.css';
import Filterbar from '../../components/Filterbar/Filterbar';
import useAuth from '../../hooks/useAuth';
import Table from '../../components/Table/Table';
import _ from 'lodash';
import { Column } from 'react-table';
import { LawType, LawTypeEnum } from '../../models/LawType';
import Button from '../../components/Button/Button';
import DenseCell from '../../components/Table/DenseCell/DenseCell';
import { useHistory } from 'react-router-dom';
import { HOME } from '../../constants/Routes';
import CompanyService from '../../services/CompanyService';
import { CompanyType, CompanyTypeEnum } from '../../models/CompanyType';
import accordionIcon from '../../assets/images/accordionIcon.svg';
import UserService from '../../services/UserService';
import CompanyUsersModal from '../../components/CompanyUsersModal/CompanyUsersModal';
import UserModel from '../../models/UserModel';
import ConsultantSubscriptionChangesService from '../../services/ConsultantSubscriptionChangesService';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import editIcon from '../../assets/images/editIcon.svg';
import EditAdminInfoModal from '../../components/Modal/EditAdminInfoModal/EditAdminInfoModal';
import CompanyDocumentModel from '../../Invoicing/models/CompanyDocumentModel';
import Modal from '../../components/Modal/Modal';
import CompanyDocuments from '../../components/CompanyDocuments/CompanyDocuments';
import AccordionTextItem from '../../components/Accordion/AccordionTextItem/AccordionTextItem';
import useMobileDevice from '../../hooks/useMobileDevice';
import ReportService from '../../services/ReportService';

interface ColumnSearch {
    id: string;
    value: string | undefined;
}

export interface Data {
    id: number;
    corporationId?: number;
    name: string;
    deleted?: boolean;
    type: CompanyType;
    adminInfo?: string;
    lawTypes: LawType[];
    primaryCompany?: boolean;
    subRows?: Data[];
    companyDocuments: CompanyDocumentModel[];
}

const initialCompanyUsersModal: {
    open: boolean;
    companyId: number;
    companyName: string;
    companyUsers: UserModel[];
} = {
    open: false,
    companyId: -1,
    companyName: '',
    companyUsers: [],
};

const CompanyLogin: FC = () => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [corporationChangesLoading, setCorporationChangesLoading] = useState(false);
    const [data, setData] = useState<Data[]>([]);
    const [globalSearch, setGlobalSearch] = useState('');
    const [columnSearch, setColumnSearch] = useState<ColumnSearch[]>([]);
    const [showColumnSearch, setShowColumnSearch] = useState(false);
    const [companyUsersModal, setCompanyUsersModal] = useState(initialCompanyUsersModal);
    const [changes, setChanges] = useState<Record<number, number>>({});
    const [editAdminInfoModal, setEditAdminInfoModal] = useState<{ open: boolean; data?: Data }>({ open: false });
    const [showDocumentModal, setShowDocumentModal] = useState<{ open: boolean; data?: CompanyDocumentModel[]; companyId?: number }>({ open: false });

    const history = useHistory();
    const { user, setUser, setCompany, setSignedInAsUser, isAboveSuperUser } = useAuth();
    const isMobileDevice = useMobileDevice();

    const initiate = async () => {
        setCompany(undefined);
        setSignedInAsUser(undefined);

        if (user) {
            try {
                setLoading(true);
                if (isAboveSuperUser()) {
                    const changes: Record<number, number> = {};
                    const changesPerCompany = await ConsultantSubscriptionChangesService().getChangesPerCompanyAsConsultant(user.userId);
                    changesPerCompany.forEach(change => {
                        const id = change.companyId;
                        changes[id] = change.numberOfChanges ? change.numberOfChanges : 0;
                    });
                    setChanges(changes);

                    if (user.connectedCorporations && user.connectedCorporations.length) {
                        setCorporationChangesLoading(true);
                        ConsultantSubscriptionChangesService()
                            .getChangesPerCorporationAsConsultant(user.userId)
                            .then(changesPerCorporation => {
                                setChanges(s => {
                                    changesPerCorporation.forEach(change => {
                                        const id = change.companyId;
                                        s[id] = change.numberOfChanges ? change.numberOfChanges : 0;
                                    });
                                    return s;
                                });
                                setCorporationChangesLoading(false);
                            });
                    }
                }

                const initialData: Data[] = [];
                user.connectedCompanies?.forEach(company => {
                    //Only show companies that have deleted-flag in dB set to 0 i.e hide deleted companies from company-login.
                    if (!company.deleted) {
                        initialData.push({
                            id: company.id,
                            deleted: company.deleted,
                            name: company.name,
                            type: company.type,
                            adminInfo: company.adminInfo,
                            lawTypes: company.lawTypes,
                            primaryCompany: company.primaryCompany,
                            companyDocuments: company.companyDocuments,
                        });
                    }
                });
                user.connectedCorporations?.forEach(corporation => {
                    //Only show corp that have deleted-flag in dB set to 0 i.e hide deleted corp from company-login.
                    if (!corporation.deleted) {
                        initialData.push({
                            id: corporation.id,
                            deleted: corporation.deleted,
                            name: corporation.name,
                            type: 'GROUP',
                            lawTypes: _.uniq(_.flatMap(corporation.companyList, c => c.lawTypes)),
                            subRows: corporation.companyList,
                            companyDocuments: [],
                        });
                    }
                });

                setData(initialData);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        initiate();
    }, []);

    const handleExportAssessmentsReport = async (corporatioId: number) => {
        try {
            setLoading(true);
            setLoadingMessage('Genererar rapport');
            await ReportService().getAssessmentReportForCorporation(corporatioId);
            setLoading(false);
            setLoadingMessage('');
        } catch (error) {
            setLoading(false);
            setLoadingMessage('');
        }
    };

    const handleColumnSearchToggle = () => {
        setShowColumnSearch(show => !show);
        setColumnSearch(s => s.map(c => ({ ...c, value: undefined })));
    };

    const handleLogin = async (companyId: number, companyName: string) => {
        // Get companyUsers when logged in as consultant.
        if (isAboveSuperUser()) {
            const companyUsers = await UserService().getCompanyUsers(companyId);
            setCompanyUsersModal({
                open: true,
                companyId,
                companyName,
                companyUsers,
            });
        } else {
            const company = await CompanyService().getCompanyById(companyId);
            setCompany(company);
            history.push(HOME);
        }
    };

    const handleCompanyUserSelect = async (userId: number) => {
        // Selected no filter when userId is 0.
        if (userId > 0) {
            const user = await UserService().getUser(userId);
            user && setSignedInAsUser(user);
        }
        const company = await CompanyService().getCompanyById(companyUsersModal.companyId);
        setCompany(company);
        history.push(HOME);
    };

    const handleAdminInfoUpdate = async (data: Data) => {
        if (user) {
            setEditAdminInfoModal({ open: false });
            setLoading(true);

            const { id, corporationId, adminInfo } = data;
            const response = await CompanyService().updateAdminInfo(id, adminInfo || '');
            if (corporationId) {
                setUser({
                    ...user,
                    connectedCorporations: user.connectedCorporations.map(corp => {
                        if (corp.id === corporationId) {
                            return {
                                ...corp,
                                companyList: corp.companyList.map(c => {
                                    if (c.id === id) {
                                        return {
                                            ...c,
                                            adminInfo: response,
                                        };
                                    }
                                    return c;
                                }),
                            };
                        }
                        return corp;
                    }),
                });
                setData(s =>
                    s.map(corp => {
                        if (corp.id === corporationId && corp.subRows) {
                            return {
                                ...corp,
                                subRows: corp.subRows.map(sr => {
                                    if (sr.id === id) {
                                        return {
                                            ...sr,
                                            adminInfo: response,
                                        };
                                    }
                                    return sr;
                                }),
                            };
                        }
                        return corp;
                    }),
                );
            } else {
                setUser({
                    ...user,
                    connectedCompanies: user.connectedCompanies.map(cc => {
                        if (cc.id === id) {
                            return {
                                ...cc,
                                adminInfo: response,
                            };
                        }
                        return cc;
                    }),
                });
                setData(s =>
                    s.map(cc => {
                        if (cc.id === id) {
                            return {
                                ...cc,
                                adminInfo: response,
                            };
                        }
                        return cc;
                    }),
                );
            }
            setLoading(false);
        }
    };

    const columns: Array<Column<Data> & { Header: string }> = useMemo(() => {
        return [
            {
                id: 'expander',
                Header: '',
                width: '42px',
                Cell: function expanderCell({ row }) {
                    return row.canExpand ? (
                        <span {...row.getToggleRowExpandedProps()} className={styled.ToggleCell}>
                            {row.isExpanded ? <img src={accordionIcon} className={styled.OpenAccordion} /> : <img src={accordionIcon} />}
                        </span>
                    ) : (
                        <span className={styled.ToggleCell} />
                    );
                },
            },
            {
                Header: t('columnCompanyName'),
                accessor: 'name',
                Cell: DenseCell,
                width: 3,
            },
            {
                id: 'reports',
                Header: t('columnAssessmentReport'),
                accessor: function Accessor(cell: any) {
                    return cell.type === 'GROUP' && cell.subRows ? (
                        <Button variant="Light" title={t('buttonAssessmentReport')} className={styled.DocumentButton} onClick={() => handleExportAssessmentsReport(cell.id)}>
                            {t('buttonAssessmentReport')}
                        </Button>
                    ) : null;
                },
                Cell: DenseCell,
                width: 1.2,
            },
            ...(isAboveSuperUser()
                ? [
                      {
                          Header: t('columnChange'),
                          id: 'changes',
                          accessor: function Accessor(cell: any) {
                              if ((cell.type === 'GROUP' && cell.subRows) || cell.type === 'STANDARD') {
                                  return null;
                              }
                              if (cell.type === 'GROUP' && corporationChangesLoading) {
                                  return <div className={styled.Loading} />;
                              }

                              return changes[cell.id] ? (
                                  <p className={styled.ChangeNumber} title={''}>
                                      {changes[cell.id]}
                                  </p>
                              ) : (
                                  <p className={styled.ChangeNumber}>{0}</p>
                              );
                          },
                          Cell: DenseCell,
                          width: 1,
                      },
                  ]
                : []),
            {
                id: 'type',
                Header: t('columnType'),
                accessor: ({ type }) => _.capitalize(CompanyTypeEnum[type]),
                Cell: DenseCell,
                width: 1,
            },
            ...(isAboveSuperUser()
                ? [
                      {
                          id: 'adminInfo',
                          Header: t('columnAdminInfo'),
                          accessor: 'adminInfo',
                          Cell: function Cell(props: any) {
                              return !props.cell.subRows ? (
                                  <>
                                      <div className={styled.AdminInfoCell} title={props.row.original.adminInfo}>
                                          <AccordionTextItem data={props.row.original.adminInfo} />
                                      </div>
                                      <img
                                          src={editIcon}
                                          title={''}
                                          onClick={() =>
                                              setEditAdminInfoModal({
                                                  open: true,
                                                  data: props.row.original,
                                              })
                                          }
                                      />
                                  </>
                              ) : null;
                          },
                          width: 3,
                      },
                  ]
                : []),
            {
                id: 'lawTypes',
                Header: t('columnLawTypes'),
                accessor: ({ lawTypes }) => lawTypes.map(lawType => t(LawTypeEnum[lawType])).join(', '),
                Cell: DenseCell,
                width: 2,
            },
            {
                id: 'documents',
                Header: t('documentsTitle'),
                accessor: function Accessor(cell) {
                    return cell.companyDocuments.length > 0 && cell.id ? (
                        <Button
                            variant="Light"
                            title={t('documentsTitle')}
                            className={styled.DocumentButton}
                            onClick={() => setShowDocumentModal({ open: true, data: cell.companyDocuments, companyId: cell.id })}
                        >
                            {t('documentsTitle')}
                        </Button>
                    ) : null;
                },
                Cell: DenseCell,
                width: 1,
            },
            {
                id: 'id',
                Cell: DenseCell,
                width: 1,
                disableFilters: true,
                Header: '',
                accessor: function Accessor(cell) {
                    return !cell.subRows ? (
                        <Button variant="Primary" title={''} className={styled.LoginButton} onClick={() => handleLogin(cell.id, cell.name)}>
                            {t('buttonLogin')}
                        </Button>
                    ) : null;
                },
            },
        ];
    }, [changes, data, corporationChangesLoading, i18n.language]);
    const mobileColumns: Array<Column<Data> & { Header: string }> = useMemo(() => {
        return [
            {
                id: 'expander',
                Header: '',
                width: '42px',
                Cell: function expanderCell({ row }) {
                    return row.canExpand ? (
                        <span {...row.getToggleRowExpandedProps()} className={styled.ToggleCell}>
                            {row.isExpanded ? <img src={accordionIcon} className={styled.OpenAccordion} /> : <img src={accordionIcon} />}
                        </span>
                    ) : (
                        <span className={styled.ToggleCell} />
                    );
                },
            },
            {
                Header: t('columnCompanyName'),
                accessor: 'name',
                Cell: DenseCell,
                width: 3,
            },

            {
                id: 'id',
                Cell: DenseCell,
                width: 1,
                disableFilters: true,
                Header: '',
                accessor: function Accessor(cell) {
                    return !cell.subRows ? (
                        <Button variant="Primary" title={''} className={styled.LoginButton} onClick={() => handleLogin(cell.id, cell.name)}>
                            {t('buttonLogin')}
                        </Button>
                    ) : null;
                },
            },
        ];
    }, [changes, data, corporationChangesLoading, i18n.language]);

    return (
        <div className={styled.CompanyLogin}>
            {loading && <LoadingSpinner message={loadingMessage} />}
            {companyUsersModal.open && (
                <CompanyUsersModal
                    companyName={companyUsersModal.companyName}
                    companyUsers={companyUsersModal.companyUsers}
                    onSubmit={handleCompanyUserSelect}
                    onClose={() => setCompanyUsersModal(initialCompanyUsersModal)}
                />
            )}
            {editAdminInfoModal.open && editAdminInfoModal.data && (
                <EditAdminInfoModal onClose={() => setEditAdminInfoModal({ open: false })} data={editAdminInfoModal.data} onSave={handleAdminInfoUpdate} />
            )}

            <div className={styled.List}>
                <Filterbar globalSearch={globalSearch} onGlobalSearchChange={setGlobalSearch} showColumnSearch={showColumnSearch} onColumnSearchToggle={handleColumnSearchToggle} />

                <div className={styled.TableWrapper}>
                    <Table
                        columns={isMobileDevice ? mobileColumns : columns}
                        data={data}
                        showColumnSearch={showColumnSearch}
                        columnSearch={columnSearch}
                        globalSearch={globalSearch}
                        rowHeight={48}
                        dense
                        expandable
                    />
                </div>
            </div>

            {showDocumentModal.open && showDocumentModal.data && showDocumentModal.data.length > 0 && (
                <Modal title={t('documentsTitle')} subtitle={'Aktuella dokument för företaget'} className={styled.CompanyLoginModal}>
                    <CompanyDocuments
                        companyId={showDocumentModal.companyId}
                        companyDocuments={showDocumentModal.data}
                        onCancel={() => setShowDocumentModal({ open: false, data: [], companyId: -1 })}
                    />
                </Modal>
            )}
        </div>
    );
};

export default CompanyLogin;
